
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getComponent } from '@/utils/helpers';

import TasqDetailsMixin from '@/components/tasqsCommon/TasqDetailsMixin';
import tasqsListModule from '@/store/modules/tasqsListModule';
import GenericMixin from '@/lib/mixins/GenericMixin';
import assetsModule from '@/store/modules/assetsModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';

// @ts-ignore
@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqDetails: () => getComponent('tasqs/TasqDetailV2'),
    WorkTicketDetail: () => getComponent('workTickets/WorkTicketDetail'),
    AddWorkTicket: () => getComponent('workTickets/AddWorkTicket'),
    ActionBubble: () => getComponent('tasqs/ActionBubble'),
  },
})
export default class TasqsDesktop extends mixins(
  TasqDetailsMixin,
  GenericMixin,
) {
  showAddWorkTicket = false;

  screenWidth = window.innerWidth;

  get activeWorkTicket() {
    return (
      tasqsListModule.activeWorkTicket
      || (this.$route.params.id && this.$route.query.type === 'workticket')
    );
  }

  get gridStyles() {
    if (!this.activeTasqExist && !this.activeWorkTicket && this.isVerticalListUnPinned && this.isVerticalListHovering) {
      return 'relative flex';
    }
    if (this.isVerticalListUnPinned && this.isVerticalListHovering) {
      return 'relative grid grid-rows-1';
    }
    if (this.isVerticalListUnPinned && !this.isVerticalListHovering) {
      return 'overlay-vertical-miniview';
    }
    return 'overlay-vertical-unpin';
  }

  get activeTasqExist() {
    if (this.isEditing && !this.isBatchResponding) {
      return false;
    }
    return (
      tasqsListModule.activeTasq
      || (this.$route.params.id
        && this.$route.query.type !== 'workticket'
        && (this.$route.query.view === 'tasq'
          || this.$route.query.view === 'wellview'))
    );
  }

  get iconSize() {
    return this.screenWidth > 1279 ? 24 : 16;
  }

  handleResize() {
    this.screenWidth = window.innerWidth;
  }

  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  async created() {
    tasqsListModule.setIsMobileView(false);
    await this.loadProducingWellData();
    await this.initializeTasqDetails();
  }

  @Watch('$route.params.id', { immediate: true })
  async onTasqRouteIdChange(id: string) {
    tasqSignalsModule.setPlungerController('abort');
    tasqSignalsModule.setSignalController('abort');
    // const { wellName } = this.$route.query;
    console.log(id);
    if (this.isLoadingFirstTimeDone) {
    if (id) {
      assetsModule.setActiveTasqID(id);
      this.showDataLoadingFeedback = true;

      tasqsListModule.setTasqDataLoadingFeedback(true);

      this.fetchTasq().then(async () => {
        tasqsListModule.updateTasqViewsCount({ predictionID: id });
        console.log('object');

        await this.sleep(300);
        tasqsListModule.setTasqDataLoadingFeedback(false);
      });

      this.padWellsSkipToBeginning();
    } else {
      tasqsListModule.setActiveTasq('');
    }
    this.multiTasqType = '';
  }
  }
}
